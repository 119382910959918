
import {
    CheckIcon,
    SparklesIcon,
    CurrencyDollarIcon,
    PencilAltIcon,
    CubeTransparentIcon,
    CalendarIcon,
    DatabaseIcon,
    DocumentIcon,
    MailIcon,
    CodeIcon,
    FingerPrintIcon

 } from '@heroicons/react/outline'
import { BadgeCheckIcon } from '@heroicons/react/solid'
import Image from 'next/image'
import Link from 'next/link'
import { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { dev, cloudfront } from "../config";
import bizStyles from '../styles/Business.module.css'
import Layout from '../components/Layouts/homeLayout'
import AlertTemplate from '../components/Notification'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { useAuth, AuthProvider } from '../contexts/Auth'
import Calendar from '../components/Gthr/Scheduler/Utils/Calendar'
import { supabase } from '../utils/supabaseClient'
import Meta from "../components/Meta"
import { useRouter } from 'next/dist/client/router'
const { loadIntercom } = require("next-intercom")
const Demo = dynamic(()=>import('../components/Demos/Demo'), {
  ssr: false
})



  const tiers = [
    {
      name: 'Monthly',
      price: 29,
      unit:"mo",
      description: 'Setup your coaching business in minutes',
      includedFeatures: ['No credit card required for trial','Built-in customizable video chat', 'Accept payments','Scheduling pages','Setup recurring or one-time services', 'Sync Google Calendar', 'Messaging system', 'Client portal', 'Rescheduling and cancellation handling'],
    },
    {
      name: 'Annual',
      price: 290,
      unit:"yr",
      description: 'Includes a complimentary website',
      includedFeatures: [
        'All monthly features',
        'Complimentary website and hosting ',
        'Includes 2 months free'
      ],
    },
    {
      name: 'Marketplace',
      price: null,
      unit:null,
      description: 'Only available to accredited coaches. Book a meeting to learn more',
      includedFeatures: [
        'Receive leads or booked meetings',
        'Variable pricing - per lead, per discovery call, or flat fee',
        '1-on-1 onboarding and support',
      ],
    },

  ]

  const features = [
    {
      name: 'Accept Payments',
      description: 'Add a payment gateway to book meetings or setup subscriptions to generate recurring revenue',
      icon: CurrencyDollarIcon,
    },
    {
      name: 'Create a Booking Page',
      description: 'Use a landing page for clients to book meetings and receive inquiries',
      icon: DocumentIcon,
    },
    {
      name: 'Schedule a variety of event types',
      description: 'Setup recurring, one-time, or multi-session events',
      icon: CalendarIcon,
    },
    {
      name: 'Client Management',
      description: "Provide clients with a login to view upcoming meetings, reschedule meetings, and send messages",
      icon: DatabaseIcon,
    },
    {
      name: 'Messaging System',
      description: 'Allow potential clients to message you without sharing an email',
      icon: MailIcon,
    },
    {
      name: 'Custom Video Template',
      description: "Customize and brand your video chat to match your goals and brand",
      icon: PencilAltIcon,
    },
    {
      name: 'Brand Your Assets',
      description: 'Upload your logo and profile pic to brand your booking pages, emails, and video chat',
      icon: FingerPrintIcon,
    },
    {
      name: 'Need a site?',
      description: 'Book a meeting to learn more about custom website design and hosting',
      icon: CodeIcon,
    },
   
  ]

export default function Home() {
  let tz = Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : "America/Los_Angeles"
  const [stopCode, setStopCode] = useState(true)
  const [ subPage, setSubPage] = useState(0)
  const [ submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ checkUser, setCheckUser] = useState(true)
  const [meeting, setMeeting] = useState({"name": "", "person":"", "email": "", "notes": "", "dateStr": null, "rand": (Math.random() + 1).toString(36).substring(7), "client_profile_id": null,
  "appointment_datetime": null, "client_user_id":null, "timezone":tz, "meeting_dates": [] })
  const { user, signOut } = useAuth()  
  const router = useRouter()  

  const handleChange = (e) => {
    let newMeeting = {...meeting}
    if (e.target) {
        newMeeting[e.target.name] = e.target.value
        setMeeting(newMeeting)
    }
}

const handleUser = (userData) => {
  let newMeeting = {...meeting}
  newMeeting.email = userData.email
  newMeeting.name = userData.name
  newMeeting.client_user_id = userData.id
  newMeeting.client_profile_id = userData.profile_id
  setMeeting(newMeeting)
}

const handleTimeChange = (newValue) => {
  let newMeeting = {...meeting}
  newMeeting.timezone = newValue.value
  setMeeting(newMeeting)
}

const handleDateStr = (dateTime) => {
  let newMeeting = {...meeting}
  newMeeting["dateStr"] = dateTime.toFormat('DD')
  newMeeting["appointment_datetime"] = dateTime.toISO()
  setMeeting(newMeeting)
}

const createAccount = async (e) => {
  e.preventDefault()
  const url = meeting.email ? `/register?email=${meeting.email}` : "/register"
  router.push(url)
}


const requestAccount = async (e) => {
  setLoading(true)
  e.preventDefault()

  const {data, error} = await supabase
    .from("main_request_access")
    .insert({email: meeting.email, type:"coach"})
  
  error && console.log(error)

  !error && setSubmitted(true)
    //might want to send email
}



    return (
        <div className="relative overflow-hidden">
            <Meta 
                title="Plaza: Set Up Your Coaching Business in Minutes"
                description="Plaza provides an all-in-one solution for virtual coaches or consultants to run their businesses.  Includes one-time or multi-session scheduling, payments, and a customizable video chat"
                
                />
        <main>
          <section className="pt-10 bg-slate-200/50 -mt-0 sm:pt-10 lg:pt-0 lg:pb-14 lg:overflow-hidden mb-2 sm:mb-40 md:mb-40 lg:mb-0">
            <div className="mx-auto max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="mx-auto h-full max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                  <div className="lg:pb-24 lg:pt-16">
                    {/* <a
                      href="#"
                      className="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                    >
                      <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-fuchsia-700 rounded-full">
                        Beta
                      </span>
                      <span className="ml-4 text-sm">We just launched!</span>
                      <ChevronRightIcon className="ml-2 w-5 h-5 text-gray-600" aria-hidden="true" />
                    </a> */}
                    <span className='text-fuchsia-700 bg-fuchsia-100 w-16 px-2 py-1 rounded-lg font-bold flex items-center justify-between'><SparklesIcon className="h-5 w-5"/> New</span>
                    <h1 className="mt-4 text-5xl tracking-tight font-extrabold mb-10 text-black sm:mt-5 sm:text-6xl lg:mt-2 xl:text-5xl">
                      <span className="block ">Start your <span className="text-fuchsia-600">coaching </span>business</span>
                      <span className="block ">in <span className="underline decoration-fuchsia-600 decoration-wavy decoration-4"> minutes</span></span>
                    </h1>
                    <div className="blur-3xl  -ml-10  absolute h-32 w-32 -z-10 bg-fuchsia-500"/>

                    <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                        From scheduling and payments to a customizable video chat, Plaza provides everything you need for your coaching or consulting business
                     
                    </p>
                    <div className="mt-10 sm:mt-12">
             
                     
                    </div>
                  </div>
                </div>
                <div className=" lg:pt-20 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                 
                     <div className="blur-3xl mt-20 -ml-10 right-0 absolute h-32 w-32 -z-10 bg-fuchsia-500"/>
                     <div className='flex flex-col sm:flex-row justify-center h-full mt-10 sm:mt-8 md:mt-8'>
                        <div className='flex flex-row sm:flex-col space-y-1 mx-auto sm:mx-0'>
                        <div className="blur-3xl  -ml-10 flex flex-row sm:flex-col   absolute h-32 w-32 -z-10 bg-fuchsia-500"/>
                            <div className='w-40 h-40 mr-3 -mb-5 overflow-hidden'>
                            <video autoPlay loop muted className='rounded-xl w-30 h-32 object-cover object-top'>
                              <source src={`${cloudfront}/media/stock_videos/video-chat-woman-talk.mp4`} type="video/mp4" />
                            </video>
    
                            </div>
                            <div className='w-40 h-40 mb-3 mr-3'>
                            <video autoPlay loop muted className='rounded-xl w-30 h-32 object-cover' >
                              <source src={`${cloudfront}/media/stock_videos/video-chat-manager.mp4`} type="video/mp4" />
                            </video>

                            </div>
                        </div>
                        <div className="h-2/3 w-2/3 mx-auto sm:mx-0">
                         <Demo 
                          stopCode={stopCode}
                          setStopCode={setStopCode}
                        //  playback={}
                         
                         /></div>
                     </div>
               
                </div>
              </div>
            </div>
          </section>
  
          {/* More main page content here... */}
          <section className="relative bg-white pt-16 pb-32 overflow-hidden">
          <div className="">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                    <div>
                    <div>
                        <span className="h-12 w-12 rounded-md flex items-center justify-center bg-fuchsia-700">
                        <CalendarIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                    </div>
                    <div className="mt-6">
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                            Scheduling and Payments
                        </h2>
                        <p className="mt-4 text-lg text-gray-500">
                           Generate your own booking pages based on your availability, automatically send reminder emails, and collect payments for 
                           one-time, recurring, or multi-session appointments
                        </p>
                        <div className="mt-6">
                        <a
                            href="#bookDemo"
                            className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-fuchsia-700 hover:bg-fuchsia-900"
                        >
                            Book a Demo
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1 flex items-center  ">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 my-auto img-cont" >
                <Image
                        className={bizStyles.editorMockup}
                        src={`${cloudfront}/static/mock-new-calendars.png`}
                        width={1920}
                        height={1180}
                        objectFit={"cover"}
                        alt="Plaza for video consultations"
                    
                    />
                    </div>
                </div>
                </div>
            </div>
            <div className="relative mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                    <div>
                    <div>
                        <span className="h-12 w-12 rounded-md flex items-center justify-center bg-fuchsia-700">
                        <PencilAltIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                    </div>
                    <div className="mt-6">
                        {/* <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                            Better understand your customers
                        </h2> */}
                       <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                          Customize Your Video Chat
                        </h2>
                        <p className="mt-4 text-lg text-gray-500">Make your video chat yours!  Customize colors and add <span className='underline decoration-fuchsia-600 decoration-dashed decoration-2'> interactive elements </span>
                        to get on the same page as your client.  Automatically export notes to your guest at the end of your meeting
                        </p>
                        {/* <p className="mt-4 text-lg text-gray-500">
                            Customer feedback is crucial when growing your startup.  Most will send a quick survey, 
                            but if you actually want to get to know your customers in a 1-on-1 conversation, try a 
                            Plaza meeting to generate better insights
                        </p> */}
                        <div className="mt-6">
                        <button
                            onClick={createAccount}
                            className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-fuchsia-700 hover:bg-fuchsia-900"
                        >
                            Register
                        </button>
                        </div>
                    </div>
                    </div>
                    {/* <div className="mt-8 border-t border-gray-200 pt-6">
                    <blockquote>
                        <div>
                        <p className="text-base text-gray-500">
                            &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci risus aenean curabitur
                            donec aliquet. Mi venenatis in euismod ut.&rdquo;
                        </p>
                        </div>
                        <footer className="mt-3">
                        <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                            <img
                                className="h-6 w-6 rounded-full"
                                src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                alt=""
                            />
                            </div>
                            <div className="text-base font-medium text-gray-700">Marcia Hill, Digital Marketing Manager</div>
                        </div>
                        </footer>
                    </blockquote>
                    </div> */}
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 flex items-center ">
                    <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 img-cont" >
                    <Image
                        className={bizStyles.mockup3}
                        src={`${cloudfront}/static/mock-3.png`}
                        width={1920}
                        height={1080}
                        alt="Plaza for video consultations"
                    
                    />
                    </div>
                </div>
                </div>
            </div>
            <div className="mt-24">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                    <div>
                    <div>
                        <span className="h-12 w-12 rounded-md flex items-center justify-center bg-fuchsia-700">
                        <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                    </div>
                    <div className="mt-6">
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                            Reduce Expenses and Save Time
                        </h2>
                        <p className="mt-4 text-lg text-gray-500">
                          Plaza combines the best features from other paid tools into one, simple, monthly subscription.
                          Replaces Zoom, Mailchimp, and Acuity Scheduling so there&apos;s less time and money spent switching between software

                        </p>
                        <div className="mt-6">
                        <button
                            onClick={createAccount}
                            className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-fuchsia-700 hover:bg-fuchsia-900"
                        >
                             Register
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1 flex items-center  ">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 my-auto flex flex-col items-center sm:   sm:flex-row w-full space-x-3" >
                  <div className='w-3/4 sm:w-1/2'>
                    <div className='bg-slate-100 rounded-lg p-5'>
                      <div className='flex space-x-4'>
                        <div>
                          <Image 
                            width="40"
                            height="40"
                            src="https://d28zq0um2frnpx.cloudfront.net/static/plaza-tail-transparent.png"
                            objectFit='cover'
                            alt="plaza icon"
                          />
                      </div>
                      <div>
                        <h3 className='font-extrabold text-2xl text-slate-800'>Plaza</h3>
                      </div>
                      </div>
                      <div className='space-y-3 mt-3 '>
                        <div className='flex items-center space-x-2 '>
                          <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                          <p>One-time, recurring, and multi-session scheduling</p>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                          <p>Payment handling with direct payouts</p>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                          <p>Automatic email reminders</p>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                          <p>Customizable video chat</p>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                          <p>Client Portal</p>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                          <p>Messaging System</p>
                        </div>
                      </div>
            
        
                    </div>
                    <div className='flex justify-center flex-col items-center text-slate-700 text-2xl mt-4 font-bold'>
                    <p className=''>$29/mo</p>
                    {/* <div className='flex mt-2'>
                      <div className='text-fuchsia-700 bg-fuchsia-100 w-32 text-sm px-2 py-1 rounded-l-lg font-bold flex items-center justify-between'><SparklesIcon className="h-5 w-5"/> Beta Pricing</div>
                      <div className='text-slate-700 bg-slate-100 w-32 text-sm  px-2 py-1 rounded-r-lg font-bold flex items-center justify-center'>Free</div>
                    </div> */}
                  </div>
           
                  </div>
                  <div className=' flex justify-center items-center '>
                    <p className='text-slate-100 rounded-full font-bold sm:my-0 my-6 h-8 w-8 text-center pt-1 bg-slate-700'>VS</p>
                  </div>
                  <div className='w-3/4 sm:w-[45%]'>
                    <div  className='space-y-[23px]  sm:mr-0 md:mr-6 lg:mr-0'>

                 

                    <div className='bg-slate-100 rounded-lg p-5 flex space-x-3 items-center  justify-between'>
                      <h3 className='font-extrabold text-lg text-slate-800'>
                      Acuity Scheduling 
                      </h3>
                      <h3 className='font-semibold text-xl text-slate-800'>
                        $15/mo
                      </h3>
        
                    </div>
                    <div className='bg-slate-100 rounded-lg p-5 flex items-center  justify-between'>
                      <h3 className='font-extrabold text-lg text-slate-800'>
                        Mailchimp
                      </h3>
                      <h3 className='font-semibold text-xl text-slate-800'>
                        $11/mo
                      </h3>
        
                    </div>
                    <div className='bg-slate-100 rounded-lg p-5 flex items-center  justify-between'>
                      <h3 className='font-extrabold text-lg text-slate-800'>
                        Zoom
                      </h3>
                      <h3 className='font-semibold text-xl text-slate-800'>
                        $15/mo
                      </h3>
        
                    </div>
                    </div>
                    <div className='flex justify-center text-slate-700 text-2xl mt-3 font-bold'>
                    <p>$41/mo</p>
                    <p></p>
                    

                  </div>
        
                  </div>
                  

                    </div>
                  
        
                </div>
                </div>
            </div>

            <div className="relative mt-20">
                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                    <div>
                    <div>
                        <span className="h-12 w-12 rounded-md flex items-center justify-center bg-fuchsia-700">
                        <CubeTransparentIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                    </div>
                    <div className="mt-6">
                        {/* <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                            Better understand your customers
                        </h2> */}
                       <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                          Showcase Your Brand
                        </h2>
                        <p className="mt-4 text-lg text-gray-500">
                          With Plaza, coaches/consultants can upload their logo once to automatically brand their scheduling page, meeting link emails, and video chat. 
                          {/* No need to string together multiple paid services to create a white label experience for clients */}

                        </p>
                        {/* <p className="mt-4 text-lg text-gray-500">
                            Customer feedback is crucial when growing your startup.  Most will send a quick survey, 
                            but if you actually want to get to know your customers in a 1-on-1 conversation, try a 
                            Plaza meeting to generate better insights
                        </p> */}
                        {/* <div className="mt-6">
                        <a
                            href="#"
                            className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-fuchsia-700 hover:bg-fuchsia-800"
                        >
                            Get started
                        </a>
                        </div> */}
                    </div>
                    </div>
                    {/* <div className="mt-8 border-t border-gray-200 pt-6">
                    <blockquote>
                        <div>
                        <p className="text-base text-gray-500">
                            &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci risus aenean curabitur
                            donec aliquet. Mi venenatis in euismod ut.&rdquo;
                        </p>
                        </div>
                        <footer className="mt-3">
                        <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                            <img
                                className="h-6 w-6 rounded-full"
                                src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                alt=""
                            />
                            </div>
                            <div className="text-base font-medium text-gray-700">Marcia Hill, Digital Marketing Manager</div>
                        </div>
                        </footer>
                    </blockquote>
                    </div> */}
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 flex items-center ">
                    <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 img-cont" >
                    <Image
                     
                        src={`${cloudfront}/static/branding_group.png`}
                        width={1920}
                        height={1080}
                        alt="Plaza for video consultations"
                    
                    />
                    </div>
                </div>
                </div>
            </div>

            <div className='bg-fuchsia-100/40 flex items-center shadow-lg rounded-lg  w-10/12 sm:w-1/2 p-5 mx-auto mt-20'>
                  <div className='mr-10'>
                      <SparklesIcon className='h-10 w-10 text-fuchsia-800 '/>
                  </div>
                  <div>
                    <p className='text-lg font-bold text-slate-800'>Need a website or an update?</p>
                    <p>For a limited time, we&apos;ll build a custom coaching website at no additonal cost with the purchase of an annual subscription</p>
                    <a href="#bookDemo"className='text-sm text-slate-800 hover:underline'>Book a demo to learn more</a>
                  </div>
            </div>
    
            </section>

            {/* <section className="bg-slate-100  ">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
            <h2>Select a template or create your own</h2>
                   Video Templates
                  Editor Templates 
      
      </div>
      </section> */}
      <section className="bg-slate-200/50 text-center relative">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 className="text-3xl mb-5 font-extrabold text-zinc-900 tracking-tight">Simple, intuitive customization</h2>
        <p className="my-4 text-lg font-thin text-zinc-700">
         Spend 5 minutes and customize your video chat to meet your needs
        </p>
        <div className="blur-3xl left-0 mr-48 absolute h-32 w-48 -z-10 bg-fuchsia-400"/>
        <div className="blur-3xl right-0 mr-48 absolute h-32 w-48 -z-10 bg-fuchsia-400"/>

        <div className="rounded-lg sm:rounded-xl img-cont">
        <Image
          className={bizStyles.editorPreview}
          src={`${cloudfront}/static/plaza-editor.PNG`}
          width={960}
          height={500}
          objectFit={'cover'}
          alt="Plaza for video consultations"
        />
        </div>


      </div>
    </section>

      <section className="bg-slate-900   ">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white tracking-tight">Available Features</h2>
        <p className="mt-4 max-w-3xl text-lg text-indigo-100">
          Let us know if you&apos;re interested in any of our features or integrations
        </p>

        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name}>
              <div>
                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-fuchsia-700">
                  <feature.icon className="h-6 w-6 -fuschsia-600 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                <p className="mt-2 text-base text-indigo-100">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

    {/* Book Meeting */}


   
    <section className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
          {/* <p className="mt-5 text-xl text-gray-500 sm:text-center">
            Start your coaching business in minutes
          </p> */}
          {/* <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
            <button
              type="button"
              className="relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-fuchsia-500 focus:z-10 sm:w-auto sm:px-8"
            >
              Monthly billing
            </button>
            <button
              type="button"
              className="ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-fuchsia-500 focus:z-10 sm:w-auto sm:px-8"
            >
              Yearly billing
            </button>
          </div> */}
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 flex flex-col sm:flex-row justify-center sm:space-x-8">
          {tiers.map((tier) => (
            <div key={tier.name} className="border w-full sm:w-80 bg-white border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
            
                {tier.name !== "Marketplace" ?
                  <div className="p-6">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                  <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                  <p className="mt-8">
                    <span className="text-4xl font-extrabold text-gray-900">${tier.price}</span>{' '}
                    <span className="text-base font-medium text-gray-500">/{tier.unit}</span>
                  </p>
                                <Link href={"/register"}>
                                <a
                                  className="mt-8 block w-full bg-fuchsia-700 border rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-fuchsia-900"
                                >
                                  {tier.name === "Monthly" ? "Start 14 day trial" : `Buy ${tier.name}`} 
                                </a>
                                </Link>
                                </div>
                :

                <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                <p className="mt-4 text-sm text-gray-500 mb-[85px]">{tier.description}</p>
                <Link href={"/network/coaches"}>
                                <a
                                  className="mt-8 block w-full bg-fuchsia-700 border rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-fuchsia-900"
                                >
                                 Learn More
                                </a>
                                </Link>
         
                              </div>
                
                }

               
     
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What&apos;s included</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

    <div className="py-16 bg-slate-200/50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>
 
        <div className="blur-3xl  ml-10  absolute h-32 w-32 -z-10 bg-fuchsia-400"/>

        {/* <div className="relative" id="bookDemo">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Book a demo
          </h2>
        </div> */}
        <div className="blur-3xl right-0 mr-48 absolute h-32 w-48 -z-10 bg-fuchsia-400"/>

    {/* <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className=" sm:mx-auto sm:w-full sm:max-w-3xl">
          <div className="bg-white  w-full shadow-xl sm:rounded-lg ">
          <Calendar
            meeting={meeting}
            handleChange={handleChange}
            handleDateStr={handleDateStr}
            handleTimeChange={handleTimeChange}
            handleUser={handleUser}
            subPage={subPage}
            setSubPage={setSubPage}
            setMeeting={setMeeting}
            business={true}
            activity={null}
            setCheckUser={setCheckUser}
            checkUser={checkUser}
            slug={"demo435"}
            
            />
          </div>
        </div>
    </div> */}
        


      </div>
      <div className=''>

<br/> <br/> <br/> <br/> <br/> <br/> 
<p className="text-center">
    <Link href="/terms-conditions" prefetch={false}>
        <a > Terms & Conditions | </a>
    </Link>
    <Link href="/cookie-policy" prefetch={false}>
        <a> Cookie Policy | </a>
    </Link>
    <Link href="/privacy-policy" prefetch={false} ><a>Privacy Policy</a></Link>
</p>
<p className="text-center">
      <p>Our network:</p>
    <Link href="https://makeclips.com" prefetch={false}>
        <a> Faceless AI Video Generator | </a>
    </Link>
    <Link href="https://ordinarymedia.com" prefetch={false} ><a>Ordinary Media</a></Link>
</p>
{/* <p className="text-center">
    <Link href="/contact" passhref>
        <Button variant="outline-purple" size="sm" className={footerStyles.no_sell_link}>Do Not Sell My Info</Button>
    </Link>
</p> */}
<p className="text-center text-gray-400">© {new Date().getFullYear()} Ordinary Media, LLC. All Rights Reserved.</p>
</div>
    </div>


        </main>

        <style jsx global>{`
         .img-cont > span {
          overflow:visible !important;
         }
         body {
          overflow-y: scroll;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none;  /* Internet Explorer 10+ */
          }
          body::-webkit-scrollbar { /* WebKit */
              width: 0;
              height: 0;
          }
        `}</style>
      </div>



    )
}


const alertOptions = {
  // you can also just use 'bottom right'
  position: positions.BOTTOM_RIGHT,
  timeout: 3000,
  offset: '10px',
  containerStyle: {'zIndex':'9999'},
  // you can also just use 'fade'
  transition: transitions.FADE,
}

Home.getLayout = function getLayout(page) {
  return (
      <AlertProvider template={AlertTemplate} {...alertOptions}>
          <AuthProvider>
              <Layout>
                  {page}
              </Layout>
          </AuthProvider>
      </AlertProvider>
  )
}


